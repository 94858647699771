import React, { useEffect, useState } from 'react';
import { Customer } from '@quiqupltd/quiqupjs';
import { MailOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { page } from '../../../../globals/analytics';
import Toast from '../../../toast/toast';
import { ReactComponent as QuiqupIcon } from '../../../../images/quiqup-long-logo.svg';
import AuthContainer from '../../../../containers/signup/v2/auth';
import { FormContainer, FormSubmit, Hint, StyledInput } from '../../styles';

const ResetPassword = (): JSX.Element => {
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  const history = useHistory();

  useEffect(() => {
    page('Loginv2');
  });

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    setLoading(true);

    return Customer.user.forgottonPassword({ email }).then((data: { status: string }) => {
      if (data.status === 'NOTFOUND') {
        setLoading(false);
        setError('Email not found');
      } else {
        setLoading(false);
        setLinkSent(true);
        history.push('/login', { resetedPassword: true });
      }
    });
  };
  return (
    <AuthContainer>
      <FormContainer className="small-box">
        <QuiqupIcon />
        <div className="title">Forgot your password?</div>
        <div className="sub-title">We’ll email instructions on how to reset it</div>
        {!linkSent ? (
          <>
            <div>
              <div className="form-label">Email</div>
              <StyledInput
                hasError={Boolean(error)}
                className="input"
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="email@address.com"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
              />
              {error && <Hint>{error}</Hint>}
            </div>
            <FormSubmit
              htmlType="submit"
              type="primary"
              disabled={false}
              size="large"
              onClick={handleSubmit}
              loading={loading}
            >
              Reset password
            </FormSubmit>
          </>
        ) : (
          <Toast
            type="success"
            message="A link to reset your password has been emailed to you."
            onClick={(): void => setLinkSent(false)}
          />
        )}
        <div className="footer-message">
          Return to
          <Link to="/login" className="link">
            Login
          </Link>
        </div>
      </FormContainer>
    </AuthContainer>
  );
};

export default ResetPassword;
