/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { AnyAction, Dispatch } from 'redux';
import { Partner, ApiClient } from '@quiqupltd/quiqupjs';
import { identifyUserWithSentry, fetchUsersSuccess, fetchUsersError } from '../user/user.actions';
import { updateWaypoints } from '../preferences/preferences.actions';
import preferenceActionTypes from '../preferences/preferences.action-types';
import userActionTypes from '../user/user.action-types';
import appActionTypes from './app.action-types';
import { State } from './app.reducer';
import { BusinessAccount, ShopifyConnection } from '../../../sameday/pages/company/types';

type PreferencesActionTypes = {
  FETCH_PREFERENCES_STARTED: 'FETCH_PREFERENCES_STARTED';
  FETCH_PREFERENCES_SUCCESS: 'FETCH_PREFERENCES_SUCCESS';
  FETCH_PREFERENCES_FAILED: 'FETCH_PREFERENCES_FAILED';
  FETCH_PREFERENCES_ERROR: 'FETCH_PREFERENCES_ERROR';
};
const { FETCH_PREFERENCES_STARTED, FETCH_PREFERENCES_SUCCESS, FETCH_PREFERENCES_FAILED } =
  preferenceActionTypes as PreferencesActionTypes;

export interface InitializationAction {
  dispatch: Dispatch<AnyAction>;
}

export function initializeApp() {
  return async ({ dispatch }: InitializationAction): Promise<void> => {
    try {
      dispatch({ type: appActionTypes.APP_INITIALIZATION_STARTED });
      const [user] = await Promise.all([fetchUserRequest(dispatch), fetchPreferencesRequest(dispatch)]);

      fetchRegionRequest(user.regionCode, dispatch); // only support number is used now
      dispatch({ type: appActionTypes.APP_INITIALIZATION_SUCCESS });

      // Fetch business accounts
      await fetchContactBusinessAccounts(dispatch);

      // Fetch user integrations
      await fetchUserIntegrations(dispatch);
      return;
    } catch (error) {
      dispatch({ type: appActionTypes.APP_INITIALIZATION_FAILED, payload: error });
      Sentry.captureMessage(getErrorMessage('General error'), {
        level: Sentry.Severity.Fatal,
        extra: { error },
      });
    }
  };
}

function fetchUserRequest(dispatch: Dispatch<AnyAction>): Promise<User> {
  dispatch({ type: userActionTypes.FETCH_USER_STARTED });
  return Partner.user
    .get()
    .then((data: User) => {
      identifyUserWithSentry(data);
      dispatch(fetchUsersSuccess(data));
      return data;
    })
    .catch((error: any) => {
      dispatch(fetchUsersError(error));
      return Promise.reject(error);
    });
}

function fetchPreferencesRequest(dispatch: Dispatch<AnyAction>): Promise<any> {
  dispatch({ type: FETCH_PREFERENCES_STARTED });
  return Partner.preferences
    .get()
    .then((preferences: any) => {
      updateWaypoints(preferences)
        .then((updatedPreferences: any) => dispatch({ type: FETCH_PREFERENCES_SUCCESS, payload: updatedPreferences }))
        .catch((error: any) => {
          dispatch({ type: FETCH_PREFERENCES_FAILED, payload: error });
          return Promise.reject(error);
        });
    })
    .catch((error: any) => {
      dispatch({ type: FETCH_PREFERENCES_FAILED, payload: error });
      return Promise.reject(error);
    });
}

async function fetchContactBusinessAccounts(dispatch: Dispatch<AnyAction>): Promise<void> {
  dispatch({ type: userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_STARTED });

  try {
    const { data }: { data?: Array<BusinessAccount> } = await ApiClient.get({
      path: `${window.env.EX_CORE_API_URL}/api/contact/business_accounts`,
    });

    dispatch({
      type: userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_FINISHED,
      data: { businessAccounts: Array.isArray(data) ? data : [] },
    });
  } catch (error) {
    dispatch({ type: userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_ERROR });
  }
}

async function fetchUserIntegrations(dispatch: Dispatch<AnyAction>): Promise<void> {
  dispatch({ type: userActionTypes.FETCH_USER_INTEGRATIONS_STARTED });

  try {
    const data: ShopifyConnection = await ApiClient.get({
      path: `${window.env.PLATFORM_API}/shopify/connections`,
    });

    dispatch({
      type: userActionTypes.FETCH_USER_INTEGRATIONS_FINISHED,
      data: data,
    });
  } catch (error) {
    dispatch({ type: userActionTypes.FETCH_USER_INTEGRATIONS_ERROR });
  }
}

function fetchRegionRequest(regionCode: Region.Code, dispatch: any): Promise<any> {
  dispatch({ type: userActionTypes.FETCH_USER_REGION_STARTED });
  return Partner.regions
    .get({ regionCode })
    .then((region: any) => {
      dispatch({
        type: userActionTypes.FETCH_USER_REGION_SUCCESS,
        payload: region,
      });
      return Promise.resolve(region);
    })
    .catch((error: any) => {
      dispatch({ type: userActionTypes.FETCH_USER_REGION_FAILED, payload: error });
      return Promise.reject(error);
    });
}

export function setAppIdle(isIdle: State['isIdle']): {
  type: typeof appActionTypes.SET_APP_IDLE;
  payload: State['isIdle'];
} {
  return {
    type: appActionTypes.SET_APP_IDLE,
    payload: isIdle,
  };
}

export function setTabVisible(isVisible: State['isIdle']): {
  type: typeof appActionTypes.SET_TAB_VISIBLE;
  payload: State['isIdle'];
} {
  return {
    type: appActionTypes.SET_TAB_VISIBLE,
    payload: isVisible,
  };
}

function getErrorMessage(message: string): string {
  return 'App Initialization: ' + message;
}
