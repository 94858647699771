import startAnalytics from './segment-analytics-tag';
import startTagManager from './google-tag-manager-script';

function startGlobals(env) {
  startAnalytics(env);
  startTagManager(env);

  window.env = {
    ADD_GOOGLE_TAG_MANAGER: env.ADD_GOOGLE_TAG_MANAGER,
    ALLOW_TEST_MOBILE_NUMBER: env.ALLOW_TEST_MOBILE_NUMBER,
    ANALYTICS_TOKEN: env.ANALYTICS_TOKEN,
    API_APP_NAME: env.API_APP_NAME,
    API_APP_PLATFORM: env.API_APP_PLATFORM,
    API_BASE_URL: env.API_BASE_URL,
    API_VERSION: env.API_VERSION,
    APP_VERSION: env.APP_VERSION,
    FINANCE_URL: env.FINANCE_URL,
    GOOGLE_API_KEY: env.GOOGLE_API_KEY,
    NODE_ENV: env.NODE_ENV,
    OSRM_URL: env.OSRM_URL,
    SITE_ENV: env.SITE_ENV,
    SOCKET_URL: env.SOCKET_URL,
    TRACK_APP_URL: env.TRACK_APP_URL,
    QU_ERY_GRAPHQL: env.QU_ERY_GRAPHQL,
    ADDRESS_BOOK_GRAPHQL: env.ADDRESS_BOOK_GRAPHQL,
    API_GATEWAY: env.API_GATEWAY,
    ORDERS_CORE_API_URL: env.ORDERS_CORE_API_URL,
    ORDERS_CORE_API_WS: env.ORDERS_CORE_API_WS,
    EX_CORE_API_URL: env.EX_CORE_API_URL,
    INVOICER_API: env.INVOICER_API,
    METABASE_URL: env.METABASE_URL,
    METABASE_ALSHAYAA_DASHBOARD_ID: env.METABASE_ALSHAYAA_DASHBOARD_ID,
    METABASE_DEFAULT_DASHBOARD_ID: env.METABASE_DEFAULT_DASHBOARD_ID,
    ZOHO_BOOKS_API: env.ZOHO_BOOKS_API,
    ZOHO_ORG_ID: env.ZOHO_ORG_ID,
    SITE_URL: env.SITE_URL,
    CARRIER_MANAGEMENT: env.CARRIER_MANAGEMENT,
    PLATFORM_API: env.PLATFORM_API,
    FINANCE_API: env.FINANCE_API,
    LOAD_STRIPE: env.LOAD_STRIPE,
    GOOGLE_API_URL: env.GOOGLE_API_URL,
  };
}

export default startGlobals;
