import actionTypeCreator from '../../action-types-creator';

type Type = {
  APP_INITIALIZATION_STARTED: 'APP_INITIALIZATION_STARTED';
  APP_INITIALIZATION_SUCCESS: 'APP_INITIALIZATION_SUCCESS';
  APP_INITIALIZATION_FAILED: 'APP_INITIALIZATION_FAILED';
  APP_INITIALIZATION_ERROR: 'APP_INITIALIZATION_ERROR';
  SET_APP_IDLE: 'SET_APP_IDLE';
  SET_TAB_VISIBLE: 'SET_TAB_VISIBLE';
};

export default actionTypeCreator({
  async: ['APP_INITIALIZATION'],
  sync: ['SET_APP_IDLE', 'SET_TAB_VISIBLE'],
}) as Readonly<Type>;
