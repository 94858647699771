/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { QuToaster, QuToast } from '@quiqupltd/QuReactSDK';
import classnames from 'classnames';

ToasterRaw.propTypes = {
  toasts: PropTypes.array,
  onClose: PropTypes.func,
  className: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
};

ToasterRaw.defaultProps = {
  toasts: [],
  onClose: () => null,
};

function ToasterRaw(props) {
  return (
    <QuToaster className={classnames(['main-toaster', props.className])} onClose={props.onClose} duration={12000}>
      {props.toasts.map((toast, index) => (
        <QuToast key={index} className={toast.className || ''}>
          {toast.message}
          {toast.link && <a onClick={() => props.history.push(toast.link.url)}>{toast.link.text}</a>}
        </QuToast>
      ))}
    </QuToaster>
  );
}

const Toaster = memo(ToasterRaw, (prevProps, nextProps) => {
  return isEqual(prevProps.toasts, nextProps.toasts);
});

export { Toaster, ToasterRaw };
