import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuMenuItems, QuMenuDrawerItem, QuMenuDrawerGroup, QuMenuDrawer, QuMenuHamburger } from '@quiqupltd/QuReactSDK';
import DeviceInfo from '@quiqupltd/QuReactSDK/lib/utils/device-info/device-info';

import { ShepherdTourContext } from 'react-shepherd';

import { Menu, MenuItem, ButtonNewOrder, DrawerInfo, MobileWhatsappLink } from '../../containers/app/style';
import { ROUTES } from '../../routing/route';
import { PRODUCT_SAME_DAY, TRACKING_CATEGORIES, trackEvent } from '../../globals/analytics';
import { WHATSAPP_URL } from '../../utils/constants';

Nav.propTypes = {
  userId: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  isDrawerVisible: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  moveToSMDSingleShipment: PropTypes.func.isRequired,
  regionSupportNumber: PropTypes.string,
};

function Nav(props) {
  const {
    userId,
    isLoading,
    isDrawerVisible,
    toggleDrawer,
    handleLogout,
    moveToSMDSingleShipment,
    regionSupportNumber,
  } = props;

  const tour = useContext(ShepherdTourContext);

  const handleTourComplete = () => {
    localStorage.setItem('onboarding', 'done');
    trackEvent({
      action: `Quiqdash tour complete`,
      category: TRACKING_CATEGORIES.support,
      product: PRODUCT_SAME_DAY,
      user: userId,
    });
  };

  const handleTourCancel = () => {
    localStorage.setItem('onboarding', 'done');
    trackEvent({
      action: `Quiqdash tour canceled`,
      category: TRACKING_CATEGORIES.support,
      product: PRODUCT_SAME_DAY,
      user: userId,
    });
  };

  useEffect(() => {
    tour.on('complete', handleTourComplete);
    tour.on('cancel', handleTourCancel);

    return () => {
      tour.off('complete', handleTourComplete);
      tour.off('cancel', handleTourCancel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);

  const handleWhatsappClick = () => {
    trackEvent({
      action: 'Whatsapp Chat Click',
      category: TRACKING_CATEGORIES.support,
      product: PRODUCT_SAME_DAY,
      user: userId,
      location: window.location.pathname,
    });
  };

  const trackHeaderLinkClick = (target) => {
    if (!target) return;
    trackEvent({
      action: 'Header Link Click',
      category: TRACKING_CATEGORIES.navigation,
      label: target,
      product: PRODUCT_SAME_DAY,
      user: userId,
      location: window.location.pathname,
    });
  };

  const handleSingleShipmentClick = (...params) => {
    trackHeaderLinkClick('Single Shipment');
    moveToSMDSingleShipment(...params);
  };

  return (
    <DeviceInfo>
      {(deviceInfo) =>
        deviceInfo.isPhone && (
          <Menu>
            <QuMenuItems>
              <QuMenuHamburger open={isDrawerVisible} onClick={toggleDrawer} />
            </QuMenuItems>
            <QuMenuItems right>
              <MenuItem>
                <ButtonNewOrder
                  className="new-order-button"
                  type="primary"
                  disabled={isLoading}
                  onClick={handleSingleShipmentClick}
                  data-test-id="newSameDay"
                >
                  New Shipment
                </ButtonNewOrder>
              </MenuItem>
            </QuMenuItems>

            <QuMenuDrawer visible={isDrawerVisible}>
              <QuMenuDrawerGroup>
                <QuMenuDrawerItem data-test-id="liveOrders">
                  <NavLink to={ROUTES.SAMEDAY.ORDERS} exact={true} onClick={() => trackHeaderLinkClick('Live Orders')}>
                    Orders
                  </NavLink>
                </QuMenuDrawerItem>
                <QuMenuDrawerItem data-testid="reports">
                  <NavLink to={ROUTES.SAMEDAY.REPORTS} exact={true} onClick={() => trackHeaderLinkClick('Reports')}>
                    Reports
                  </NavLink>
                </QuMenuDrawerItem>
                <QuMenuDrawerItem onClick={moveToSMDSingleShipment} data-test-id="newSameDay">
                  Single Shipment
                </QuMenuDrawerItem>

                <QuMenuDrawerItem data-test-id="bulk-upload">
                  <NavLink to={ROUTES.SAMEDAY.BULK} exact={true}>
                    Bulk Upload
                  </NavLink>
                </QuMenuDrawerItem>

                <QuMenuDrawerItem onClick={handleLogout}>Log Out</QuMenuDrawerItem>
              </QuMenuDrawerGroup>
              <QuMenuDrawerGroup>
                <QuMenuDrawerItem>
                  <DrawerInfo>
                    Chat with our live support team
                    <br />
                    over{' '}
                    <MobileWhatsappLink
                      title="Chat on Whatsapp"
                      href={WHATSAPP_URL}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleWhatsappClick}
                    >
                      Whats App
                    </MobileWhatsappLink>{' '}
                    or Call Support
                    <br />
                    {regionSupportNumber}
                  </DrawerInfo>
                </QuMenuDrawerItem>
              </QuMenuDrawerGroup>
            </QuMenuDrawer>
          </Menu>
        )
      }
    </DeviceInfo>
  );
}

export default connect()(Nav);
