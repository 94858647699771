import Immutable from 'immutable';
import userActionTypes from './user.action-types.js';
import * as i18n from '../../../utils/i18n';

export function createInitialState() {
  return Immutable.fromJS({
    isFetching: false,
    isLoggedIn: false,
    isNewUser: false,
    isContactUser: false,
    errorMessage: '',
    userData: {},
    region: null,
    hasPickupZones: false,
    savedPickups: [],
    defaultSavedPickup: undefined,
    businessAccounts: [],
    defaultBusinessAccount: undefined,
    integrationData: {
      shopify: undefined,
    },
  });
}

function userReducer(state = createInitialState(), action = { type: undefined }) {
  switch (action.type) {
    case userActionTypes.LOGIN_STARTED:
      return state.merge({
        isFetching: true,
        isLoggedIn: false,
      });
    case userActionTypes.UPDATE_USER_STARTED:
      return state.merge({
        isFetching: true,
        errorUpdate: {},
      });
    case userActionTypes.UPDATE_USER_SUCCESS:
      return state
        .merge({
          isFetching: false,
          errorMessage: '',
          errorUpdate: {},
        })
        .mergeIn(['userData'], setUserData(action.payload));
    case userActionTypes.UPDATE_USER_FAILED:
      return state.merge({
        isFetching: false,
        errorUpdate: action.error.errors,
      });
    case userActionTypes.FETCH_LOGIN_SUCCESS:
      return state.merge({
        isFetching: false,
        isLoggedIn: true,
      });
    case userActionTypes.FETCH_LOGIN_FAILED:
      return createInitialState().set('errorMessage', 'Invalid login or password');
    case userActionTypes.LOGOUT:
      return createInitialState();
    case userActionTypes.FETCH_USERS_SUCCESS:
      return state
        .merge({
          isLoggedIn: true,
          isFetching: false,
          errorMessage: '',
        })
        .mergeIn(['userData'], setUserData(action.payload));
    case userActionTypes.FETCH_USERS_FAILED:
      return state.merge({
        isLoggedIn: false,
        isFetching: false,
        errorMessage: 'Invalid login or password',
      });
    case userActionTypes.FETCH_USER_STARTED:
      return state.set('isFetching', true);
    case userActionTypes.FETCH_USER_REGION_SUCCESS: {
      const regionCode = action.payload.code;
      const countryCode = regionCode ? regionCode.slice(0, regionCode.indexOf('.')) : undefined;
      action.payload.isUnitedArabEmirates = countryCode === 'uae';
      action.payload.countryCode = countryCode;

      return state
        .merge({
          region: Immutable.fromJS(action.payload),
        })
        .setIn(['locale'], i18n.getFor(regionCode));
    }
    case userActionTypes.USER_CREATED:
      return state.merge({ isNewUser: true });
    case userActionTypes.ONBOARDING_COMPLETE:
      return state.merge({ isNewUser: false });
    case userActionTypes.FETCH_PICKUP_ZONES:
    case userActionTypes.FETCH_PICKUP_ZONES_SUCCESS:
      return state.merge({
        hasPickupZones: action?.payload?.data?.UserHasPickupZones?.hasPickupZones,
      });
    case userActionTypes.FETCH_PICKUP_ZONES_FAILED:
    case userActionTypes.FETCH_PICKUP_ZONES_ERROR:
      return state.merge({ hasPickupZones: false });
    case userActionTypes.FETCH_SAVED_ADDRESSES:
    case userActionTypes.FETCH_SAVED_ADDRESSES_SUCCESS: {
      const { defaultUserContact, contacts } = action.payload.data;
      return state.merge({
        savedPickups: contacts
          .filter((contact) => contact.locationName)
          .map((contact) => {
            return {
              label: contact.locationName,
              value: contact.id,
              contactName: contact.fullName,
              phone: contact.phoneNumber,
              region: contact.town,
              ...contact,
            };
          }),
        defaultSavedPickup: defaultUserContact,
      });
    }
    case userActionTypes.FETCH_SAVED_ADDRESSES_FAILED:
    case userActionTypes.FETCH_SAVED_ADDRESSES_ERROR:
      return state.merge({ savedPickups: [], defaultSavedPickup: undefined });

    case userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_STARTED:
      return state.set('isFetching', true);
    case userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_FINISHED: {
      const { businessAccounts } = action.data;
      let defaultBusinessAccount;

      let selectedBusinessAccount = JSON.parse(localStorage.getItem('selectedBusinessAccount'));
      if (!selectedBusinessAccount) {
        defaultBusinessAccount = businessAccounts[0];
      } else {
        defaultBusinessAccount =
          businessAccounts.find((business) => business.id === selectedBusinessAccount.id) || businessAccounts[0];
      }

      return state.merge({
        businessAccounts: businessAccounts,
        isContactUser: Array.isArray(businessAccounts) && businessAccounts.length,
        defaultBusinessAccount,
        isFetching: false,
      });
    }
    case userActionTypes.FETCH_USER_BUSINESS_ACCOUNTS_ERROR: {
      // return state;
      return state.merge({
        businessAccounts: [],
        defaultBusinessAccount: undefined,
        isContactUser: false,
        isFetching: false,
      });
    }
    case userActionTypes.SET_BUSINESS_ACCOUNT: {
      localStorage.setItem('selectedBusinessAccount', JSON.stringify(action.payload));
      return state.merge({
        defaultBusinessAccount: action.payload,
      });
    }
    case userActionTypes.FETCH_USER_INTEGRATIONS_STARTED:
      return state.set('isFetching', true);
    case userActionTypes.FETCH_USER_INTEGRATIONS_FINISHED: {
      return state.merge({
        integrationData: {
          shopify: action.data,
        },
        isFetching: false,
      });
    }
    case userActionTypes.FETCH_USER_INTEGRATIONS_ERROR: {
      return state.merge({
        integrationData: {
          shopify: undefined,
        },
        isFetching: false,
      });
    }
    default:
      return state;
  }
}

export default userReducer;

export function setUserData(user) {
  return {
    waypointConstraintWindowMinutes: user.waypointConstraintWindowMinutes,
    lastname: user.lastname,
    mobile: user.mobile,
    businessName: user.businessName,
    businessPhone: user.businessPhone,
    id: user.id,
    gid: user.gid,
    active: user.active,
    gcmToken: user.gcmToken,
    hasPendingPayments: user.hasPendingPayments,
    temporaryPassword: user.temporaryPassword,
    inQueue: user.inQueue,
    courier: user.courier,
    displayName: user.displayName,
    updatedAt: user.updatedAt,
    activated: user.activated,
    status: user.status,
    pushId: user.pushId,
    fullname: user.fullname,
    firstname: user.firstname,
    mobileVerificationRequired: user.mobileVerificationRequired,
    email: user.email,
    customerBalance: user.customerBalance,
    isNewUser: user.isNewUser,
    isContactUser: user.isContactUser,
    businessAccounts: user.businessAccounts,
    locale: i18n.getFor(user.regionCode),
    isBulkBeta: user.roles && user.roles.includes('bulk_beta'),
    isMapBeta: user.roles && user.roles.includes('map_beta'),
    isPaginationBeta: user.roles && user.roles.includes('pagination_beta'),
    roles: user.roles,
    isParentAccount: user.isParentAccount,
    isAlshayaaAccount: user.roles && user.roles.includes('reports_beta'),
    salesforceId: user.salesforceId,
  };
}
