export default function queueMiddleware() {
  const queues = {};

  function dequeue(key) {
    const { action, resolve, next } = queues[key][0];

    resolve(
      next(action).then((results) => {
        queues[key].shift();

        if (queues[key].length > 0) {
          dequeue(key);
        }

        return results;
      })
    );
  }

  return (next) => (action) => {
    const queue = action && action.promise && action.payload && action.payload.queue;

    if (!queue) return next(action);

    delete action.payload.queue;

    const actionPromise = new Promise((resolve) => {
      queues[queue] = queues[queue] || [];
      queues[queue].push({ action, resolve, next });
    });

    if (queues[queue].length === 1) dequeue(queue);

    return actionPromise;
  };
}
