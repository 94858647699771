import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import { persistState } from 'redux-devtools';
import quiqupThunk from './middleware/quiqup-thunk';
import queueMiddleware from './middleware/queue';
import { isDevelopment } from './../environment';

export default function createStore(initialState = {}) {
  const middleware = [queueMiddleware, quiqupThunk];

  let finalCreateStore;
  if (isDevelopment && window.devToolsExtension) {
    finalCreateStore = compose(
      applyMiddleware(...middleware),
      window.devToolsExtension(),
      persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/))
    )(_createStore);
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore);
  }

  const store = finalCreateStore(reducer, initialState);

  if (isDevelopment && module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(reducer);
    });
  }

  return store;
}
