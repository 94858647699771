import locale from './locale.json';
import PropTypes from 'prop-types';
import { REGION_CODE_DUBAI } from '../constants';

export const getFor = (regionCode) => locale[regionCode] || locale[REGION_CODE_DUBAI];

export const addressPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
});

export const localePropTypes = PropTypes.shape({
  features: PropTypes.shape({
    hasLocationName: PropTypes.bool.isRequired,
    hasApartmentNumber: PropTypes.bool.isRequired,
    standardRestrictionTime: PropTypes.string.isRequired,
  }).isRequired,
  timezone: PropTypes.string,
  location: PropTypes.shape({
    address1: addressPropTypes.isRequired,
    address2: addressPropTypes.isRequired,
  }).isRequired,
  account: PropTypes.shape({
    addressPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
});

export const getRegionCode = (regionName) => {
  for (const [key, value] of Object.entries(locale)) {
    if (value.name === regionName) return key;
  }
  return regionName;
};
