import { CheckOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

type Props = {
  type: 'success' | 'warning' | 'error';
  message: string;
  onClick: () => void;
};

type StyleProps = {
  type: 'success' | 'warning' | 'error';
};

const colors = {
  success: '#0E9F6E',
  error: '#0E9F6E',
  warning: '#E3A008',
};

const iconBackgroundColors = {
  success: '#DEF7EC',
  error: '#DEF7EC',
  warning: '#FDF6B2',
};

const backgroundColors = {
  success: '',
  error: '',
  warning: '#FFFAE7',
};

const borderColors = {
  success: '#84E1BC',
  error: '#84E1BC',
  warning: '##FFE15C',
};

const ToastContainer = styled.div<StyleProps>`
  padding: 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid;
  border-color: ${(props): string => borderColors[props.type]};
  background-color: ${(props): string => backgroundColors[props.type]};
  color: ${(props): string => colors[props.type]};
`;

const IconWrapper = styled.div<StyleProps>`
  background-color: ${(props): string => iconBackgroundColors[props.type]};
  color: ${(props): string => colors[props.type]};
  border-radius: 8px;
  padding: 10px 11px 10px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(CloseOutlined)<StyleProps>`
  color: ${(props): string => colors[props.type]};
`;

const Toast = (props: Props): JSX.Element => {
  const { type, message, onClick } = props;
  return (
    <ToastContainer type={type}>
      <IconWrapper type={type}>
        {type === 'success' ? (
          <CheckOutlined className="icon" />
        ) : type === 'warning' ? (
          <WarningOutlined className="icon" />
        ) : null}
      </IconWrapper>
      <div>{message}</div>
      <CloseIcon type={type} onClick={onClick} />
    </ToastContainer>
  );
};

export default Toast;
