import { Map } from 'immutable';
import actionTypes from './toasts.action-types';

const { TOASTS_ADD, TOASTS_REMOVE, TOASTS_REMOVE_ALL } = actionTypes;

let lastIndex = 0;

/**
 * reducer
 */
function createInitialState() {
  return Map();
}

export default function toastsReducer(state = createInitialState(), action = { type: undefined }) {
  switch (action.type) {
    case TOASTS_ADD: {
      const index = lastIndex++;
      return state.set(index, { ...action.payload, index });
    }
    case TOASTS_REMOVE:
      return state.delete(action.payload.index);
    case TOASTS_REMOVE_ALL:
      return createInitialState();
    default:
      return state;
  }
}
