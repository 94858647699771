import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from '@quiqupltd/quiqupjs';
import App from '../../containers/app/app';

const PrivateRoute = ({ component: Component, requiresLoggedOut, ...rest }) => {
  // Routes that require you to be logged out: /signup, /login. In this case users should be redirected to /live
  if (requiresLoggedOut) {
    return (
      <Route
        {...rest}
        render={(props) =>
          Auth.hasToken() ? (
            <Redirect to={{ pathname: '/live', state: { nextPathname: rest.location.pathname } }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.hasToken() ? (
          <App {...props}>
            <Component {...props} />
          </App>
        ) : (
          <Redirect to={{ pathname: '/login', state: { nextPathname: rest.location.pathname } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  requiresLoggedOut: PropTypes.bool,
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  requiresLoggedOut: false,
};

export default PrivateRoute;
