/* eslint-disable */
function startTagManager(env) {
  function googleTagManager(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  if (env.ADD_GOOGLE_TAG_MANAGER === 'true') {
    googleTagManager(window, document, 'script', 'dataLayer', 'GTM-KPJ857S');
  }
}

export default startTagManager;
/* eslint-enable */
