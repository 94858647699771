import { AnyAction } from 'redux';
import appActionTypes from './app.action-types';

export type State = {
  error: { [key: string]: string | number | Record<string, unknown> } | null;
  isLoading: boolean;
  isInitialized: boolean;
  isIdle: boolean;
  isTabVisible: boolean;
};

export const initialState: State = {
  error: null,
  isLoading: true,
  isInitialized: false,
  isIdle: false,
  isTabVisible: true,
};

function reducer(state: State = initialState, action: AnyAction): State {
  switch (action.type) {
    case appActionTypes.APP_INITIALIZATION_STARTED:
      return initialState;
    case appActionTypes.APP_INITIALIZATION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        isInitialized: true,
      };
    case appActionTypes.APP_INITIALIZATION_FAILED:
    case appActionTypes.APP_INITIALIZATION_ERROR:
      return {
        ...state,
        isInitialized: false,
        isLoading: false,
        error: action.payload,
      };
    case appActionTypes.SET_APP_IDLE:
      return {
        ...state,
        isIdle: action.payload,
      };
    case appActionTypes.SET_TAB_VISIBLE:
      return {
        ...state,
        isTabVisible: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
