import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '@quiqupltd/QuReactSDK/lib/styles/mixins/media';

const Container = styled.div`
  background: ${(props) => (props.isOrderPlacementAllowed ? props.theme.sunflower500 : props.theme.peach500)};
  color: ${(props) => props.theme.midnight500};
  padding: 20px 35px 25px;
  ${media.down('tablet')`
    padding: 20px 15px 25px;
`};
`;
const StyledTitle = styled.h3`
  color: ${(props) => props.theme.midnight500};
`;

const GlobalNotifications = ({ title, message, isOrderPlacementAllowed = true }) => {
  return (
    <Container isOrderPlacementAllowed={isOrderPlacementAllowed}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {typeof message === 'string' ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
    </Container>
  );
};

GlobalNotifications.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOrderPlacementAllowed: PropTypes.bool,
};

export default GlobalNotifications;
