import KeyMirror from 'keymirror';

const userActionTypes = new KeyMirror({
  LOGIN_STARTED: null,
  LOGOUT: null,
  FETCH_LOGIN_SUCCESS: null,
  FETCH_LOGIN_FAILED: null,
  FETCH_USER_STARTED: null,
  FETCH_USERS_SUCCESS: null,
  FETCH_USERS_FAILED: null,
  FETCH_USER_REGION_STARTED: null,
  FETCH_USER_REGION_SUCCESS: null,
  FETCH_USER_REGION_FAILED: null,
  FETCH_USER_REGION_ERROR: null,
  USER_CREATED: null,
  ONBOARDING_COMPLETE: null,
  UPDATE_USER: null,
  UPDATE_USER_STARTED: null,
  UPDATE_USER_FAILED: null,
  UPDATE_USER_ERROR: null,
  UPDATE_USER_SUCCESS: null,
  FETCH_PICKUP_ZONES: null,
  FETCH_PICKUP_ZONES_STARTED: null,
  FETCH_PICKUP_ZONES_SUCCESS: null,
  FETCH_PICKUP_ZONES_FAILED: null,
  FETCH_PICKUP_ZONES_ERROR: null,
  FETCH_SAVED_ADDRESSES: null,
  FETCH_SAVED_ADDRESSES_STARTED: null,
  FETCH_SAVED_ADDRESSES_SUCCESS: null,
  FETCH_SAVED_ADDRESSES_FAILED: null,
  FETCH_SAVED_ADDRESSES_ERROR: null,
  FETCH_USER_BUSINESS_ACCOUNTS_STARTED: null,
  FETCH_USER_BUSINESS_ACCOUNTS_FINISHED: null,
  FETCH_USER_BUSINESS_ACCOUNTS_ERROR: null,
  SET_BUSINESS_ACCOUNT: null,
  FETCH_USER_INTEGRATIONS_STARTED: null,
  FETCH_USER_INTEGRATIONS_FINISHED: null,
  FETCH_USER_INTEGRATIONS_ERROR: null,
});

Object.freeze(userActionTypes);

export default userActionTypes;
