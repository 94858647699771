import actionTypeCreator from '../../action-types-creator';

export default actionTypeCreator({
  async: [
    'UPDATE_PREFERENCE',
    'UPDATE_DEFAULT',
    'REMOVE_DEFAULT',
    'REMOVE_PREFERENCE',
    'FETCH_PREFERENCES',
    'DELETE_PREFERENCE',
  ],
});
