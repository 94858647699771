import React from 'react';
import { Container } from './styles';
import { ReactComponent as BackgroundIcon } from '../../../images/background-icon.svg';

type Props = {
  children: React.ReactNode;
};

const AuthContainer = ({ children }: Props): JSX.Element => {
  return (
    <Container>
      <BackgroundIcon className="background-icon" />
      {children}
    </Container>
  );
};

export default AuthContainer;
