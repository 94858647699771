import styled, { css, keyframes } from 'styled-components';
import theme from '@quiqupltd/QuReactSDK/lib/styles/theme';
import { LogoutOutlined, ShoppingCartOutlined, SyncOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { ReactComponent as Message } from '../../images/message.svg';
import { ReactComponent as Help } from '../../images/help.svg';
import { NavLink } from 'react-router-dom';
export const Menu = styled.div<{ isCollapsed: boolean }>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props): string => (props.isCollapsed ? '50px' : '192px')};
  height: 100%;
  background: ${(props): string => props.theme.midnight500};
  overflow-y: auto;
  transition: 400ms;
`;
export const CollapseButton = styled.div<{ isCollapsed: boolean }>`
  position: fixed;
  left: ${(props): string => (props.isCollapsed ? '40px' : '182px')};
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props): string => props.theme.midnight500};
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
  transition: 400ms;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 4px;
`;

export const PersonalContainer = styled(MenuContainer)`
  border-top: 1px solid ${(props): string => props.theme.midnight400};
  gap: 4px;
`;

export const StyledMenuItem = styled.div<{ selected?: boolean; isCollapsed?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: ${(props): string => (props.isCollapsed ? '100%' : '175px')};
  height: 36px;
  background-color: ${(props): string => (props.selected ? theme.cornFlower500 : 'transparent')};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.cornFlower500};
  }
`;

export const LogoutIcon = styled(LogoutOutlined)`
  font-size: 1.5rem;
  && {
    margin-top: 3px;
    color: ${theme.cornFlower300};
  }
`;
export const ReplayIcon = styled(SyncOutlined)`
  font-size: 1.5rem;
  && {
    padding-left: 3px;
    margin-top: 3px;
    color: ${theme.cornFlower300};
  }
`;

export const IconContainer = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  color: ${theme.cornFlower300};
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: inline;
  }
`;

const fadeOut = keyframes`
  0% {
    display: none;
  }
  100% {
    display: none;
  }
`;

export const SmallText = styled.span<{ isCollapsed?: boolean }>`
  animation: ${(props): ReturnType<typeof css> | string =>
    props.isCollapsed
      ? css`
          ${fadeOut} 0.5s forwards
        `
      : css`
          ${fadeIn} 0.5s forwards
        `};
  font-size: 1.3rem;
  white-space: nowrap;
  visibility: ${(props): string => (props.isCollapsed ? 'hidden' : 'visible')};
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const AnimatedText = styled.span<{ isCollapsed?: boolean }>`
  animation: ${(props): ReturnType<typeof css> | string =>
    props.isCollapsed
      ? css`
          ${fadeOut} 0.5s forwards
        `
      : css`
          ${fadeIn} 0.5s forwards
        `};
`;

export const WhatsappIcon = styled(WhatsAppOutlined)`
  && {
    margin-top: 4px;
    padding-left: 3px;
    color: ${theme.cornFlower300};
    font-size: 1.5rem;
  }
`;
export const HelpIcon = styled(Help)`
  margin-top: 3px;
`;
export const MessageIcon = styled(Message)`
  margin-top: 2px;
  && {
    font-size: 1.5rem;
  }
`;
export const CartIcon = styled(ShoppingCartOutlined)`
  margin-top: 2px;
`;
export const StyledNavLink = styled(NavLink)<{ isCollapsed: boolean }>`
  width: ${(props): string => (props.isCollapsed ? '33px' : '140px')};
  overflow: 'hidden';
`;
