import React from 'react';
import { Result } from 'antd';
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary';
import { ErrorBoundary } from './error-boundary';

type Props = {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
} & ErrorBoundaryProps;

type FallBackProps = { title: string; subTitle: string };

export const FallBack = ({ title, subTitle }: FallBackProps): JSX.Element => {
  return <Result status="500" title={title} subTitle={subTitle} />;
};

export const PageErrorBoundary = ({
  children,
  title = 'Error',
  subTitle = 'Sorry, something went wrong.',
  ...otherProps
}: Props): JSX.Element => {
  return (
    <ErrorBoundary fallback={<FallBack title={title} subTitle={subTitle} />} {...otherProps}>
      {children}
    </ErrorBoundary>
  );
};
