import Immutable from 'immutable';
import settingsActionTypes from './settings.action-types.js';

function createInitialState() {
  return Immutable.fromJS({
    openingHours: [],
  });
}

const settingsReducer = (state = createInitialState(), action = { type: undefined }) => {
  switch (action.type) {
    case settingsActionTypes.FETCH_SETTINGS_SUCCESS:
      return state.merge({
        openingHours: action.payload.openingHours,
        contact: action.payload.contact,
      });
    default:
      return state;
  }
};

export default settingsReducer;
