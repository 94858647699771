import styled from 'styled-components';
import { QuIcon, QuButton, QuMenu, QuMenuItem } from '@quiqupltd/QuReactSDK/lib';
import { media } from '@quiqupltd/QuReactSDK/lib/styles/mixins/media';
import { UploadOutlined, TableOutlined } from '@ant-design/icons';

export const IconMD = styled(QuIcon)`
  ${media.down('desktop')`
    display: none;
`};
`;
export const IconSM = styled(QuIcon)`
  ${media.up('desktop')`
    display: none;
`};
`;

export const Menu = styled(QuMenu)`
  min-height: 78px;
  ${media.down('desktop')`
    padding: 5px;

    .var-logo {
      padding-top: 3px;
    }
  `};
`;

export const FulfillmentWrapper = styled.div`
  position: relative;

  .tag {
    position: absolute;
    bottom: 18px;
    right: 0px;
    color: ${(p) => p.theme.sunflower500};
  }
`;

export const MenuItem = styled(QuMenuItem)`
  ${media.down('desktop')`
    margin-left: 10px;
    margin-right: 10px;
  `};

  ${media.up('desktop')`
    &.var-logo {
      position: relative;
      top: 4px;
    }
  `};
`;

export const SwapItem = styled.div`
  margin-right: 20px;
`;

export const ButtonNewOrder = styled(QuButton)`
  text-transform: none;
  min-width: 118px;
  padding: 0 10px;

  ${media.down('desktop')`
    min-width: 111px;
    width: 100%;
    padding: 0 5px;
  `};
`;

export const SingleShipmentIcon = styled(UploadOutlined)`
  margin-right: 10px;
`;

export const BulkUploadIcon = styled(TableOutlined)`
  margin-right: 10px;
`;

export const DrawerInfo = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
`;

export const ProfileItem = styled.li`
  background: ${(props) => (props.isActive ? 'rgba(255, 255, 255, 0.2)' : '')};
  display: flex;
  align-items: center;
  user-select: none;
  .qu-icon {
    display: flex;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  a {
    color: inherit;
    width: 100%;
    text-decoration: none;
  }
`;

export const StaticProfileItem = styled(ProfileItem)`
  background-color: ${(props) => props.theme.midnight500};
  font-size: 1.25rem !important;
  cursor: default;
`;

export const ArrowRight = styled(QuIcon)`
  margin-left: auto;
  path {
    stroke: #fff;
  }
`;

export const ProfileIemText = styled.span`
  display: inline-block;
  margin-left: 8px;
  color: ${(props) => (props.isLogout ? props.theme.red : 'inherit')};
`;

export const LogoutIcon = styled(QuIcon)`
  svg {
    color: ${(props) => props.theme.red};
  }
`;

export const WhatsappItem = styled(QuMenuItem)`
  opacity: 1;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.6;
  }
  a {
    opacity: 1;
    svg {
      display: block;
    }
  }
`;

export const QManualItem = styled(QuMenuItem)`
  opacity: 1;

  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;

  transition: opacity 0.4s;
  &:hover {
    opacity: 0.6;
  }

  a {
    opacity: 1;
  }
`;

export const MobileWhatsappLink = styled.a`
  text-decoration: underline !important;
`;

export const Accordion = styled.div`
  &:hover {
    svg {
      opacity: 0.6;
      transition: opacity 0.4s;
    }
  }

  position: relative;
  ${media.down('desktop')`
    height: 36px;
    display: flex;
    align-items: center;
  `};

  ${'' /* creates an invisible element that keeps the hover state  */} &::before {
    content: '';
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 28px;
    right: 10%;
    z-index: 999;
  }

  ${'' /* triangle for tooltip */} &::after {
    content: '';
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid ${(props) => props.theme.midnight500};
    right: 40%;
    position: absolute;
    top: 56px;
    z-index: 999;
  }
`;

export const Content = styled.div`
  position: absolute;
  width: 260px;
  border-radius: 5px;
  min-width: 123px;
  background-color: ${(props) => props.theme.midnight500};
  display: none;
  max-height: 0;
  overflow: hidden;
  top: 64px;
  right: 10%;
  transition: all 0.2s ease-in;
  z-index: 13;
  ${(props) =>
    props.isOpen &&
    `
    display: block;
    max-height: 21.42857em;
  `} li {
    padding: 13px 15px;
    user-select: none;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;
export const StyledQuiqdash = styled.div`
  flex-direction: ${(props) => (props.isPhone ? 'column' : 'row')};
`;

export const StyledContentContainer = styled.div`
  width: ${(props) => (props.isPhone ? '100%' : `calc(100% - ${props.isCollapsed ? '50px' : '192px'})`)};
  margin-left: ${(props) => (props.isPhone ? 'auto' : `${props.isCollapsed ? '50px' : '192px'}`)};
  transition: 400ms;
`;
