import { Partner, Customer } from '@quiqupltd/quiqupjs';
import preferenceActionTypes from './preferences.action-types';
import { PICKUP, DROPOFF } from '../../../utils/constants';
import capitalize from 'lodash/capitalize';
import { getDefaultDetails } from './preferences.selectors';

const { UPDATE_DEFAULT, REMOVE_DEFAULT, FETCH_PREFERENCES, DELETE_PREFERENCE } = preferenceActionTypes;

const preferenceAttribute = (type) => `default${capitalize(type)}Details`;

const extendLocation = async (details) => {
  const location = await Customer.locations.getById(details.locationId);
  return Object.assign({}, location, details);
};

const tryExtendLocation = async (details) => (details.locationId ? await extendLocation(details) : details);

export const updateWaypoints = async (preferences) => {
  if (!preferences.defaultPickupDetails) preferences.defaultPickupDetails = {};
  if (!preferences.defaultDropoffDetails) preferences.defaultDropoffDetails = {};

  const [defaultPickupDetails, defaultDropoffDetails] = await Promise.all([
    tryExtendLocation(preferences.defaultPickupDetails),
    tryExtendLocation(preferences.defaultDropoffDetails),
  ]);

  preferences.defaultPickupDetails = defaultPickupDetails;
  preferences.defaultDropoffDetails = defaultDropoffDetails;

  return preferences;
};

export const fetchPreferences =
  () =>
  async ({ dispatch }) => {
    return dispatch({
      type: FETCH_PREFERENCES,
      promise: Partner.preferences.get().then(updateWaypoints),
    });
  };

export const updatePreference =
  (preference) =>
  ({ dispatch }) =>
    dispatch({
      type: UPDATE_DEFAULT,
      promise: Partner.preferences.update(preference),
      payload: preference,
    });

export const removeDefault = (data) => {
  data.locationId = undefined;
  const payload = {
    [preferenceAttribute(data.type)]: Object.keys(data).reduce((obj, item) => {
      obj[item] = '';
      return obj;
    }, {}),
  };

  return ({ dispatch, getState }) => {
    async function getPreferences() {
      const preferences = await Partner.preferences.update(payload);

      if (data.type === PICKUP) {
        preferences.defaultDropoffDetails = {
          ...preferences.defaultDropoffDetails,
          ...getDefaultDetails(getState(), DROPOFF),
        };
      } else {
        preferences.defaultPickupDetails = {
          ...preferences.defaultPickupDetails,
          ...getDefaultDetails(getState(), PICKUP),
        };
      }
      return preferences;
    }

    const action = {
      type: REMOVE_DEFAULT,
      promise: getPreferences,
      payload,
    };
    return dispatch(action);
  };
};

export const deletePreference =
  (key) =>
  ({ dispatch }) =>
    dispatch({
      type: DELETE_PREFERENCE,
      promise: Partner.preferences.remove(key),
      payload: { key },
    });
