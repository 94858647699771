import { Carousel, Progress } from 'antd';
import React from 'react';
import { FormContainer, ReviewBox } from '../../styles';
import { ReactComponent as QuiqupIcon } from '../../../../images/quiqup-long-logo.svg';

const reviews = [
  {
    message:
      'Really impressed on how fast they were. Got my delivery 1h and a half after speaking to their call centre! Cannot believe they were so fast.',
    date: 'July 31st from Christine Kairis',
  },
  {
    message:
      "Finally a delivery service with polite drivers that don't call 73646 times demanding location 'send pin'; reasonably priced considering their excellent service",
    date: 'July 31st from Christine Kairis',
  },
  {
    message: 'Great service! Easy, quick, efficient, and polite. 5 stars',
    date: 'August 1st from Samia Azfer',
  },
];

interface Props {
  progress: number;
}

const LoadingAccount = (props: Props): JSX.Element => {
  return (
    <FormContainer className="small-box">
      <QuiqupIcon />
      <div className="title">We are setting up your account 🎉</div>
      <div className="progress-bar">
        {props.progress === 100 ? (
          <div>
            Your account creation request has been received. You will be notified once your email is ready for login.
          </div>
        ) : (
          <Progress percent={props.progress} status="active" strokeColor={'#6C65F7'} />
        )}
      </div>
      <div className="reviews-title">Shoppers love our delivery experience </div>
      <Carousel autoplay>
        {reviews.map((review) => (
          <ReviewBox key={review.date}>
            <div className="review-rating">⭐ ⭐ ⭐ ⭐ ⭐️ </div>
            <div className="review-message">{review.message}</div>
            <div className="review-date">{review.date}</div>
          </ReviewBox>
        ))}
      </Carousel>
      <div className="footer-message" style={{ textAlign: 'center' }}>
        You will be redirected to our platform in a few seconds
      </div>
    </FormContainer>
  );
};

export default LoadingAccount;
