import { Tooltip as TooltipAntd } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { StyledMenuItem } from './styles';
type TooltipProps = {
  className?: string;
  children: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right';
  title: string;
  color?: string;
  selected?: boolean;
  isCollapsed: boolean;
  onHover?: () => void;
};
export const HiddenButton = styled.button`
  display: none;
`;
export const Tooltip = ({
  className,
  children,
  placement,
  title,
  selected,
  color = '#4b2e78',
  isCollapsed,
  onHover,
}: TooltipProps): JSX.Element => (
  <TooltipAntd placement={placement} title={title} className={className} color={color}>
    <HiddenButton />
    <StyledMenuItem onMouseEnter={onHover} selected={selected} isCollapsed={isCollapsed}>
      {children}
    </StyledMenuItem>
  </TooltipAntd>
);
