import Immutable from 'immutable';
import { camelize } from 'humps';

import preferencesActionTypes from './preferences.action-types';

const {
  CREATE_DEFAULT_STARTED,
  CREATE_DEFAULT_SUCCESS,
  CREATE_DEFAULT_FAILED,
  UPDATE_DEFAULT_STARTED,
  UPDATE_DEFAULT_SUCCESS,
  UPDATE_DEFAULT_FAILED,
  REMOVE_DEFAULT_STARTED,
  REMOVE_DEFAULT_SUCCESS,
  REMOVE_DEFAULT_FAILED,
  FETCH_PREFERENCES_STARTED,
  FETCH_PREFERENCES_SUCCESS,
  FETCH_PREFERENCES_FAILED,
  DELETE_PREFERENCE_STARTED,
  DELETE_PREFERENCE_FAILED,
  DELETE_PREFERENCE_SUCCESS,
} = preferencesActionTypes;

export function getInitialState() {
  return Immutable.fromJS({
    error: '',
    isFetching: false,
    isEdit: false,
    ecommerceDeliveryOptions: 'delivery_4_hours_sameday_nextday',
    partner4hrCutOffTime: '17:00',
    sameDayCutOffTime: '13:00',
    nextDayCutOffTime: '23:50',
    sameDayOtherEmiratesCutOffTime: '12:00',
    nextDayOtherEmiratesCutOffTime: '16:30',
  });
}

export function partnerDefaults(state = getInitialState(), action = { type: undefined }) {
  switch (action.type) {
    case FETCH_PREFERENCES_STARTED:
    case DELETE_PREFERENCE_STARTED:
      return state.merge({ isFetching: true, error: '' });
    case FETCH_PREFERENCES_SUCCESS:
      return state.merge({ isFetching: false, error: '', ...action.payload });
    case FETCH_PREFERENCES_FAILED:
      return state.merge({ isFetching: false, error: action.error });
    case CREATE_DEFAULT_STARTED:
    case UPDATE_DEFAULT_STARTED:
    case REMOVE_DEFAULT_STARTED:
      return state.merge({ isFetching: true });
    case CREATE_DEFAULT_SUCCESS:
      return state.merge({
        isFetching: false,
        isEdit: false,
        error: '',
        ...action.payload,
      });
    case UPDATE_DEFAULT_SUCCESS:
    case REMOVE_DEFAULT_SUCCESS:
      return state.merge({
        isFetching: false,
        isEdit: false,
        isRemove: false,
        error: '',
        ...action.payload,
      });
    case CREATE_DEFAULT_FAILED:
    case UPDATE_DEFAULT_FAILED:
    case REMOVE_DEFAULT_FAILED:
    case DELETE_PREFERENCE_FAILED:
      return state.merge({
        isFetching: false,
        error: 'Problem with request',
      });
    case DELETE_PREFERENCE_SUCCESS: {
      const normalisedKey = camelize(action.initialPayload.key);
      return state.delete(normalisedKey).set('isFetching', false);
    }
    default:
      return state;
  }
}

export default partnerDefaults;
