import React from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary';
import { Redux } from '../../redux';

type ReduxStore = {
  user: Redux.State['user'];
  settings: Redux.State['settings'];
  preferences: Redux.State['preferences'];
};

type NormalizedReduxStore = {
  reduxStore: ReduxStore;
};

export const getNormalizedReduxStore = (state: Redux.State): NormalizedReduxStore => {
  return {
    reduxStore: {
      user: state.user.toJS(),
      settings: state.settings.toJS(),
      preferences: state.preferences.toJS(),
    },
  };
};

type Props = {
  extras?: { [key: string]: string | number | Record<string, unknown> };
  reduxStore: ReduxStore;
  children: React.ReactNode;
} & ErrorBoundaryProps;

const ErrorBoundaryComponent = ({ extras, reduxStore, ...otherProps }: Props): JSX.Element => {
  return (
    <Sentry.ErrorBoundary
      {...otherProps}
      beforeCapture={(scope): void => {
        scope.setExtras({
          ...extras,
          ...reduxStore,
        });
      }}
    >
      {otherProps.children}
    </Sentry.ErrorBoundary>
  );
};

export const ErrorBoundary = connect(getNormalizedReduxStore)(ErrorBoundaryComponent);
