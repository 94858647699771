import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form'; // Import the useForm hook
import { FormContainer, FormSubmit, Hint, StyledInput } from '../../components/auth/styles';
import { ReactComponent as QuiqupIcon } from '../../images/quiqup-long-logo.svg';
import AuthContainer from '../signup/v2/auth';
import { REQUIRED_MESSAGE } from '../../components/auth/login/v2/login';
import { connect } from 'react-redux';
import { Redux } from '../../redux';
import { useHistory } from 'react-router-dom';
import { Partner } from '@quiqupltd/quiqupjs';
import { getRootRouteByRole } from '../../routing/route';
import { Button, Modal, Result } from 'antd';

interface LoginFormData {
  password: string;
  newPassword: string;
}
interface Props {
  roles: string[];
  temporaryPassword: boolean;
}

const PasswordRecovery = (props: Props): JSX.Element => {
  const history = useHistory();
  const [apiError, setApiError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    if (props.temporaryPassword) {
      return;
    }
    history.push(getRootRouteByRole(props.roles ?? []));
  });

  const onSubmit = (data: LoginFormData): void => {
    const { password, newPassword } = data;
    setApiError('');
    setShowSuccess(false);
    if (password !== newPassword) {
      setError('password', { message: 'Passwords do not match' });
      setError('newPassword', { message: 'Passwords do not match' });
    } else {
      setIsLoading(true);

      Partner.user
        .update({
          password,
          passwordConfirmation: newPassword,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(() => {
          setIsLoading(false);
          setShowSuccess(true);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          reset();
          setApiError(error.errorDetail);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const pushToHome = (): void => history.push(getRootRouteByRole(props.roles ?? []));
  return (
    <AuthContainer>
      <FormContainer className="small-box" onSubmit={handleSubmit(onSubmit)}>
        <QuiqupIcon />
        <div className="title">Password Recovery</div>
        <div>
          <div className="form-label">Password</div>
          <Controller
            name="password"
            control={control}
            rules={{ required: REQUIRED_MESSAGE }}
            render={({ onChange, value, name }): JSX.Element => (
              <StyledInput
                hasError={errors.password}
                name={name}
                className="input"
                type="password"
                onChange={onChange}
                value={value}
                placeholder="Password"
                data-testid="password-input"
              />
            )}
          />
          {errors.password && <Hint>{errors.password.message}</Hint>}
        </div>
        <div>
          <div className="form-label">New Password</div>
          <Controller
            name="newPassword"
            control={control}
            rules={{ required: REQUIRED_MESSAGE }}
            render={({ onChange, value, name }): JSX.Element => (
              <StyledInput
                hasError={errors.newPassword}
                className="input"
                type="password"
                placeholder="New Password"
                name={name}
                onChange={onChange}
                value={value}
                data-testid="new-password-input"
              />
            )}
          />
          {errors.password && <Hint>{errors.password.message}</Hint>}
        </div>
        {apiError && <div>{apiError}</div>}
        <FormSubmit
          htmlType="submit"
          type="primary"
          disabled={false}
          size="large"
          loading={isLoading}
          data-testid="change-password-input"
        >
          Change Password
        </FormSubmit>
      </FormContainer>
      <Modal visible={showSuccess} footer={null} closable={false}>
        <Result
          status="success"
          title="Successfully Changed Password"
          extra={[
            <Button onClick={pushToHome} type="primary" key="home" data-testid="go-homepage">
              Go Homepage
            </Button>,
          ]}
        />
      </Modal>
    </AuthContainer>
  );
};

const mapStateToProps = (state: Redux.State): { roles: string[]; temporaryPassword: boolean } => {
  return {
    roles: state.user.getIn(['userData', 'roles']),
    temporaryPassword: state.user.getIn(['userData', 'temporaryPassword']),
  };
};

export default connect(mapStateToProps)(PasswordRecovery);
