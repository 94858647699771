import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

interface SidebarContextProps {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContextValue: SidebarContextProps = {
  isCollapsed: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsCollapsed: () => {},
};
export const SidebarStateContext = React.createContext<SidebarContextProps>(initialContextValue);

const LayoutSettingsContextProvider = ({ children }: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(JSON.parse(localStorage.getItem('isCollapsed') || 'false'));

  useEffect(() => {
    localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  return (
    <SidebarStateContext.Provider value={{ isCollapsed, setIsCollapsed }}>{children}</SidebarStateContext.Provider>
  );
};

export default LayoutSettingsContextProvider;
