import React, { useEffect } from 'react';

interface FreshworksWidgetProps {
  widgetId?: number;
}

const FreshworksWidget: React.FC<FreshworksWidgetProps> = ({ widgetId = 154000001787 }) => {
  useEffect(() => {
    type FreshworksWindow = typeof window & {
      FreshworksWidget?: unknown;
      fwSettings?: object;
    };

    const win: FreshworksWindow = window;

    // eslint-disable-next-line @typescript-eslint/camelcase
    win.fwSettings = { widget_id: widgetId };
    const injectScript = (): void => {
      if (typeof win.FreshworksWidget === 'undefined') {
        ((): void => {
          const n: { q: unknown[] } = { q: [] };
          win.FreshworksWidget = n;
          const t = document.createElement('script');
          t.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
          t.async = true;
          t.defer = true;
          document.head.appendChild(t);
        })();
      }
    };

    injectScript();

    return (): void => {
      const script = document.querySelector(`script[src="https://widget.freshworks.com/widgets/${widgetId}.js"]`);
      if (script) {
        script.remove();
      }
    };
  }, [widgetId]);

  return null;
};

export default FreshworksWidget;
