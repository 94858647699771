import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form'; // Import the useForm hook
import { notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import AuthContainer from '../../../../containers/signup/v2/auth';
import { fetchLogin } from '../../../../redux/modules/user/user.actions';
import { ReactComponent as QuiqupIcon } from '../../../../images/quiqup-long-logo.svg';
import { getRootRouteByRole } from '../../../../routing/route';
import { Redux } from '../../../../redux';
import { page } from '../../../../globals/analytics';
import { FormContainer, FormSubmit, Hint, StyledInput } from '../../styles';

export const REQUIRED_MESSAGE = 'This field is required';

interface LoginFormData {
  email: string;
  password: string;
}

interface Props {
  loginData: {
    errorMessage: string;
    isFetching: boolean;
  };
}

const Login = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<{ resetedPassword: boolean }>();

  useEffect(() => {
    if (state?.resetedPassword) {
      notification.info({
        message: `Reset Password`,
        description: 'A link to reset your password has been emailed to you.',
        placement: 'topRight',
      });
    }
  }, [state?.resetedPassword]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(); // Initialize the hook

  useEffect(() => {
    page('Loginv2');
  });

  const onSubmit = async (data: LoginFormData): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/await-thenable, @typescript-eslint/no-explicit-any
    const token: any = await dispatch(fetchLogin(data));
    const queryParams = new URLSearchParams(location.search);
    const returnUrl = queryParams.get('returnUrl') || '';

    if (token.temporaryPassword) {
      history.push('/password-recovery');
      return;
    }

    history.push(getRootRouteByRole(token.roles, returnUrl));
  };

  return (
    <AuthContainer>
      <FormContainer className="small-box" onSubmit={handleSubmit(onSubmit)}>
        <QuiqupIcon />
        <div className="title">Log in</div>
        <div>
          <div className="form-label">Email</div>
          <Controller
            name="email" // Add the name attribute
            control={control} // Pass the control object
            rules={{ required: REQUIRED_MESSAGE }} // Define validation rules
            render={({ onChange, value, name }): JSX.Element => (
              <StyledInput
                hasError={errors.email}
                name={name}
                className="input"
                onChange={onChange}
                value={value}
                prefix={<MailOutlined style={{ color: '#514A5C' }} />}
                placeholder="email@address.com"
              />
            )}
          />
          {errors.email && <Hint>{errors.email.message}</Hint>}
        </div>
        <div>
          <div className="form-label">Password</div>
          <Controller
            name="password" // Add the name attribute
            control={control} // Pass the control object
            rules={{ required: REQUIRED_MESSAGE }} // Define validation rules
            render={({ onChange, value, name }): JSX.Element => (
              <StyledInput
                hasError={errors.password}
                className="input"
                type="password"
                placeholder="password"
                name={name}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors.password && <Hint>{errors.password.message}</Hint>}
          {props.loginData.errorMessage && <Hint>{props.loginData.errorMessage}</Hint>}
          <Link to="/reset-password" className="forgot-pass-label">
            Forgot password
          </Link>
        </div>
        <FormSubmit htmlType="submit" type="primary" disabled={false} size="large" loading={props.loginData.isFetching}>
          Log in
        </FormSubmit>
        <div className="footer-message">
          Don&lsquo;t have an account?
          <Link to="/create-account" className="link">
            Sign up
          </Link>
        </div>
      </FormContainer>
    </AuthContainer>
  );
};

export default connect((state: Redux.State) => ({
  loginData: {
    errorMessage: state.user.get('errorMessage'),
    isFetching: state.user.get('isFetching'),
  },
}))(Login);
