// // // Import styles. Why? Because webpack is using order of styles based on order they are required,
// // // so this will make our scss files to be first and then override them by styled-components flat selectors
import './styles/main.scss';

// // // Import 3rd party libraries
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ShepherdTour } from 'react-shepherd';
import theme from '@quiqupltd/QuReactSDK/lib/styles/theme';
import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import { QueryClient, QueryClientProvider } from 'react-query';

// // // Import 1st party libraries
import QuiqupJS from '@quiqupltd/quiqupjs';

// Import utils and config
import store from './redux/store';
import Routes from './routing/routes';
import packageJson from '../package.json';
import configEnv from './configEnv';
import { GlobalStyle } from './globalStyle';
import startGlobals from './globals';
import newSteps from './steps';
import { isProduction } from './environment';
import { TIMEZONE_DUBAI } from './utils/constants';
import { FallBack } from './components/error/page-error-boundary';
import { getNormalizedReduxStore } from './components/error/error-boundary';
import 'shepherd.js/dist/css/shepherd.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
      label: 'skip',
    },
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
      ],
    },
  },

  useModalOverlay: true,
};

GlobalStyle;

configEnv.loadEnv().then(async (env) => {
  startGlobals(env);
  if (isProduction) {
    Sentry.init({
      dsn: 'https://2f657eec273d4c28afac50dbcecb0d84@sentry.dev.quiq.ly/22',
      environment: env.SITE_ENV,
      release: `quiqdash@${packageJson.version}`,
      normalizeDepth: 10,
    });
  }

  // if (isDevelopment) {
  //   // inspect unnecessary component updates
  //   const { whyDidYouUpdate } = require('why-did-you-update')
  //   whyDidYouUpdate(React)
  // }

  // Bootstrap application
  QuiqupJS.config.update({
    api: {
      ...env,
      API_APP_VERSION: packageJson.version,
      API_USER_AGENT: navigator.userAgent,
      gatewayUrl: env.API_GATEWAY,
    },
  });

  moment.tz.setDefault(TIMEZONE_DUBAI);

  const dest = document.getElementById('root');
  const root = createRoot(dest);

  root.render(
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('Error Handler', 'root');
        const { reduxStore } = getNormalizedReduxStore(store.getState());
        scope.setExtras(reduxStore);
      }}
      fallback={<FallBack title={'Error'} subTitle={'Sorry, something went wrong.'} />}
    >
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Provider store={store} key="provider">
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Provider>
          </ThemeProvider>
        </QueryClientProvider>
      </ShepherdTour>
    </Sentry.ErrorBoundary>
  );
});
