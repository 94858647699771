import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Controller, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';
import { Select } from 'antd';
import {
  CheckCircleFilled,
  MailFilled,
  PhoneFilled,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Auth } from '@quiqupltd/quiqupjs';

import useFormState, { fields } from './useFormState';
import {
  FormContainer,
  FormSubmit,
  DoubleContainer,
  InfoContainer,
  AccountTypeWrapper,
  AccountTypeBox,
  Hint,
  StyledInput,
  FlexWrapper,
} from '../../styles';
import LoadingAccount from '../../loading-account/v2/loading-account';
import { ReactComponent as UserIcon } from '../../icons/user-icon.svg';
import { ReactComponent as CompanyIcon } from '../../icons/company-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../icons/archive-icon.svg';

import Toast from '../../../toast/toast';
import { ReactComponent as QuiqupIcon } from '../../../../images/quiqup-long-logo.svg';
import AuthContainer from '../../../../containers/signup/v2/auth';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../../../utils/constants';
import { Redux } from '../../../../redux';
import { TRACKING_CATEGORIES, page, trackEvent } from '../../../../globals/analytics';
import { client as GraphQLClient } from '../../../../containers/address-book/address-book-client';

const CheckCircleIcon = styled(CheckCircleFilled)`
  color: ${(p): string => p.theme.midnight500};
  height: 16px;
  width: 16px;
`;

const HintsContainer = styled.div`
  padding-top: 2rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 5px);
    border-radius: 4px;
    height: 39px;
    display: flex;
    align-items: center;
  }
`;

const infoContent = [
  {
    title: 'Quick Start',
    message: 'Start delivering across the UAE today, no contracts or minimum volume required.',
  },
  {
    title: 'Reliable Delivery',
    message: 'Trust us to deliver with a 98% success rate, even during peak periods.',
  },
  {
    title: 'Immediate Support',
    message: 'Get 24/7 support via WhatsApp or phone for any inquiries or concerns.',
  },
];

interface Props {
  loginData: {
    errorMessage: string;
    isFetching: boolean;
  };
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const CreateAccount = (props: Props): JSX.Element => {
  const [accountType, setAccountType] = useState<null | 'individual' | 'business'>();
  const [passwordErrors, setPasswordErrors] = useState({
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecial: false,
    minLength: false,
  });
  const location = useLocation();
  const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const query = useQuery();
  const form = useFormState();
  const { control, handlers, errors, values, setValue: setFormValue } = form;

  const utmContent = query.get('utm_content') || undefined;
  const utmMedium = query.get('utm_medium') || undefined;
  const utmTerm = query.get('utm_term') || undefined;
  const utmCampaign = query.get('utm_campaign') || undefined;
  const utmSource = query.get('utm_source') || undefined;

  useEffect(() => {
    setFormValue('utm', queryParameters.get('utm'));
  }, [queryParameters, setFormValue]);

  useEffect(() => {
    page('ssup_signup_page_visited');
  }, []);

  useEffect(() => {
    Auth.removeToken();
    GraphQLClient().clearStore();
  }, []);

  const handleIndividualClick = (): void => {
    setAccountType('individual');
    trackEvent({
      action: 'ssup_signup_page_individual_type_button_clicked',
      category: TRACKING_CATEGORIES.signup,
    });
  };

  const trackAction = (type: string): (() => void) | undefined => {
    return trackEvent({
      action: type,
      category: TRACKING_CATEGORIES.signup,
    });
  };

  useEffect(() => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      document.getElementsByName(firstErrorField)[0]?.focus();
    }
    if (Object.keys(errors).length > 0) {
      trackAction('ssup_signup_page_signup_button_clicked_validation_error');
    }
  }, [errors]);

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecial = /[@#$!%]/.test(value);
    const minLength = value.length >= 8;

    setPasswordErrors({
      hasUpper,
      hasLower,
      hasNumber,
      hasSpecial,
      minLength,
    });
  };

  const getPasswordErrors = (): JSX.Element => {
    const criteria: { key: keyof typeof passwordErrors; message: string }[] = [
      { key: 'minLength', message: 'Password must be at least 8 characters long.' },
      { key: 'hasUpper', message: 'Password must contain at least one uppercase letter (A-Z).' },
      { key: 'hasLower', message: 'Password must contain at least one lowercase letter (a-z).' },
      { key: 'hasNumber', message: 'Password must contain at least one number (0-9).' },
      { key: 'hasSpecial', message: 'Password must contain at least one special character (@, #, $, !, %, etc.).' },
    ];

    return (
      <HintsContainer>
        {criteria.map(({ key, message }) => (
          <Hint key={key} color={passwordErrors[key] ? 'green' : 'red'}>
            {passwordErrors[key] ? <CheckOutlined /> : <CloseOutlined />} {message}
          </Hint>
        ))}
      </HintsContainer>
    );
  };
  return (
    <AuthContainer>
      {values.creatingUser > -1 && <LoadingAccount progress={values.creatingUser} />}
      <DoubleContainer shouldShow={values.creatingUser === -1}>
        <InfoContainer>
          <div className="title">E-commerce made easy!</div>
          {infoContent.map((info) => (
            <>
              <div className="sub-title" key={info.title}>
                <CheckCircleIcon />
                <div>{info.title}</div>
              </div>
              <div className="text">{info.message}</div>
            </>
          ))}
        </InfoContainer>
        <FormContainer
          data-testid="account-form"
          onSubmit={handlers.handleSubmit((data) => {
            handlers.onSubmit({
              ...data,
              signedTerms: TERMS_OF_SERVICE_URL,
              utmCampaign: utmCampaign,
              utmContent: utmContent,
              utmMedium: utmMedium,
              utmSource: utmSource,
              utmTerm: utmTerm,
            });
          })}
        >
          <div>
            <QuiqupIcon />
          </div>
          <div className="title">Create an account in 🕒 30 sec.</div>
          <div>
            <div>
              <div className="form-label">Account type*</div>
              <AccountTypeWrapper>
                <AccountTypeBox selected={accountType === 'individual'} onClick={handleIndividualClick}>
                  <UserIcon />
                  <div className="account-title">Individual</div>
                </AccountTypeBox>
                <AccountTypeBox selected={accountType === 'business'} onClick={(): void => setAccountType('business')}>
                  <CompanyIcon />
                  <div className="account-title">Business</div>
                </AccountTypeBox>
              </AccountTypeWrapper>
            </div>
            {accountType === 'individual' && (
              <FlexWrapper>
                <Toast
                  type="warning"
                  message="Sorry, we can't proceed with signup. Currently Quiqup works only with businesses 😔"
                  onClick={(): void => setAccountType(null)}
                />
              </FlexWrapper>
            )}
            {accountType === 'business' && (
              <FlexWrapper>
                <div>
                  <div className="form-label">Full name*</div>
                  <Controller
                    name={fields.fullName.name}
                    control={control}
                    rules={fields.fullName.rules as RegisterOptions}
                    render={({ onChange, value, name }): JSX.Element => (
                      <StyledInput
                        hasError={Boolean(errors['fullName'])}
                        name={name}
                        className="input"
                        onChange={onChange}
                        value={value}
                        prefix={<UserOutlined style={{ color: '#514A5C' }} />}
                        placeholder="First and Last Name"
                      />
                    )}
                  />
                  {errors['fullName'] && <Hint data-testid="error-fullName">{errors['fullName'].message}</Hint>}
                </div>
                <div>
                  <div className="form-label">Company name*</div>
                  <Controller
                    name={fields.accountName.name}
                    control={control}
                    rules={fields.accountName.rules as RegisterOptions}
                    render={({ onChange, value, name }): JSX.Element => (
                      <StyledInput
                        hasError={Boolean(errors['accountName'])}
                        name={name}
                        onChange={onChange}
                        value={value}
                        className="input"
                        prefix={<CompanyIcon style={{ color: '#514A5C' }} />}
                        placeholder="Name on trade license"
                      />
                    )}
                  />
                  {errors['accountName'] && (
                    <Hint data-testid="error-accountName">{errors['accountName'].message}</Hint>
                  )}
                </div>
                <div>
                  <div className="form-label">Number of orders per month*</div>
                  <SelectWrapper>
                    <ArchiveIcon className="prefix-icon-wrapper" style={{ color: '#514A5C' }} />
                    <Controller
                      name={fields.ordersPerMonth.name}
                      control={control}
                      rules={fields.ordersPerMonth.rules as RegisterOptions}
                      render={({ onChange, value }): JSX.Element => (
                        <Select
                          placeholder="Select number of orders per month"
                          className="input"
                          value={value}
                          onChange={onChange}
                          options={[
                            { value: 'just starting', label: 'Just starting' },
                            { value: '1-100', label: '1-100 orders per month' },
                            { value: '101-500', label: '101-500 orders per month' },
                            { value: '500+', label: '500+ orders per month' },
                          ]}
                        />
                      )}
                    />
                  </SelectWrapper>
                  {errors['ordersPerMonth'] && (
                    <Hint data-testid="error-ordersPerMonth">{errors['ordersPerMonth'].message}</Hint>
                  )}
                </div>
                <div>
                  <div className="form-label">Mobile number*</div>
                  <Controller
                    name={fields.phone.name}
                    control={control}
                    rules={fields.phone.rules as RegisterOptions}
                    render={({ onChange, value, name }): JSX.Element => (
                      <StyledInput
                        hasError={Boolean(errors['phone'])}
                        name={name}
                        onChange={onChange}
                        value={value}
                        className="input"
                        type="number"
                        prefix={
                          <>
                            <PhoneFilled style={{ color: '#514A5C', marginRight: '2px' }} /> +971
                          </>
                        }
                        placeholder=" 501 234 5567"
                      />
                    )}
                  />
                  {errors['phone'] && <Hint data-testid="error-phone">{errors['phone'].message}</Hint>}
                </div>
                <div>
                  <div className="form-label">Email*</div>
                  <Controller
                    name={fields.email.name}
                    control={control}
                    rules={fields.email.rules as RegisterOptions}
                    render={({ onChange, value, name }): JSX.Element => (
                      <StyledInput
                        hasError={Boolean(errors['email'])}
                        name={name}
                        onChange={onChange}
                        value={value}
                        className="input"
                        prefix={<MailFilled style={{ color: '#514A5C' }} />}
                        placeholder="email@address.com"
                      />
                    )}
                  />
                  {errors['email'] && <Hint data-testid="error-email">{errors['email'].message}</Hint>}
                </div>
                <div>
                  <div className="form-label">Password*</div>
                  <Controller
                    name={fields.password.name}
                    control={control}
                    rules={fields.password.rules as RegisterOptions}
                    render={({ onChange, value, name }): JSX.Element => (
                      <StyledInput
                        hasError={Boolean(errors['password'])}
                        name={name}
                        onChange={(e): void => {
                          onChangePassword(e);
                          onChange(e);
                        }}
                        value={value}
                        className="input"
                        type="password"
                        placeholder="password"
                      />
                    )}
                  />
                  {getPasswordErrors()}
                  {props.loginData.errorMessage && <Hint>{props.loginData.errorMessage}</Hint>}
                </div>
              </FlexWrapper>
            )}
            <div className="footer-message">
              By clicking on &apos;Sign up&apos; you agree to Quiqup&apos;s
              <Link to={{ pathname: TERMS_OF_SERVICE_URL }} target="_blank" className="link">
                Terms of Service
              </Link>
              <span className="link-text">&</span>
              <Link to={{ pathname: PRIVACY_POLICY_URL }} target="_blank" className="link">
                Privacy Policy
              </Link>
              .
            </div>
            <FormSubmit
              htmlType="submit"
              type="primary"
              disabled={!accountType || accountType === 'individual'}
              size="large"
              loading={form.state.isSubmitting}
            >
              Sign up
            </FormSubmit>
          </div>
          <div className="footer-message" style={{ textAlign: 'center' }}>
            Already registered?
            <Link to="/login" className="link">
              Login
            </Link>
          </div>
        </FormContainer>
      </DoubleContainer>
    </AuthContainer>
  );
};

export default connect((state: Redux.State) => ({
  loginData: {
    errorMessage: state.user.get('errorMessage'),
    isFetching: state.user.get('isFetching'),
  },
}))(CreateAccount);
