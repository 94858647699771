import React, { ElementType } from 'react';
import { Tooltip } from './sidebar-tooltip';
import { AnimatedText, IconContainer, SmallText, StyledMenuItem } from './styles';

interface MenuItemWithIconProps {
  isCollapsed: boolean;
  isSelected?: boolean;
  icon: ElementType | null;
  label: string;
  isMain?: boolean;
  onClick?: () => void;
  dataTestid?: string;
}

const MenuItem = ({
  isCollapsed,
  isSelected,
  icon: IconComponent,
  label,
  isMain,
  onClick,
  dataTestid,
}: MenuItemWithIconProps): JSX.Element => {
  const content = (
    <>
      <IconContainer>{IconComponent && <IconComponent />}</IconContainer>
      {isMain ? (
        <AnimatedText isCollapsed={isCollapsed}>{label}</AnimatedText>
      ) : (
        <SmallText isCollapsed={isCollapsed}>{label}</SmallText>
      )}
    </>
  );

  return isCollapsed ? (
    <Tooltip title={label} placement="right" isCollapsed={isCollapsed}>
      <div onClick={onClick} data-testid={dataTestid}>
        {content}
      </div>
    </Tooltip>
  ) : (
    <StyledMenuItem selected={isSelected} onClick={onClick} data-testid={dataTestid}>
      {content}
    </StyledMenuItem>
  );
};

export default MenuItem;
