import { ShepherdOptionsWithType } from 'react-shepherd';
import { LABEL_SVG } from './onboarding-svgs';

const commonStepProps = {
  classes: 'quiqup-tour',
  highlightClass: 'highlight',
  scrollTo: true,
  id: 'onboarding-guide',
};

function createStep(overrides: Partial<ShepherdOptionsWithType>): ShepherdOptionsWithType {
  return {
    ...commonStepProps,
    beforeShowPromise: (): Promise<void> =>
      waitForElementToDisplay(typeof overrides.attachTo?.element === 'string' ? overrides.attachTo.element : ''),
    ...overrides,
  };
}

const newSteps: ShepherdOptionsWithType[] = [
  createStep({
    attachTo: { element: '#single-shipment-button', on: 'bottom' },
    advanceOn: { selector: '#sidebar-order-link', event: 'click' },
    title: 'Start Placing Your Order.',
    text: [
      '<div>Get started by clicking the "<span class="highlight-1-shepherd">Single Shipment</span>" button.</div>',
    ],
    buttons: [
      // Explicitly define the buttons for the first step to remove the cancel button
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
    cancelIcon: { enabled: false },
  }),

  createStep({
    id: 'download-modal',
    attachTo: { element: '#download-label-button', on: 'bottom' },
    advanceOn: { selector: '#download-label-button', event: 'click' },
    title: 'Download Label.',
    text: [
      `<div>Download the shipping label, pack your items securely, and <span class="highlight-shepherd">attach the labels to the package</span>.</div><div class="icon-wrapper-shepherd">${LABEL_SVG}</div>
     `,
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
  }),
  createStep({
    attachTo: {
      element: 'td.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last',
      on: 'right',
    },
    advanceOn: {
      selector: 'td.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last',
      event: 'click',
    },
    title: 'Selected Order(s).',
    text: [
      `<div>Select order and mark it as “<span class="highlight-shepherd">Ready for Collection</span>”. We will assigned a driver to pick up the order.</div>
     `,
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
  }),
  createStep({
    attachTo: { element: '#ready-for-collection-btn', on: 'bottom' },
    advanceOn: { selector: '#ready-for-collection-btn', event: 'click' },
    title: 'Mark Order Ready for Collection.',
    text: [
      `<div>By marking your orders as "<span class="highlight-shepherd">Ready for Collection</span>" you notify our couriers that the selected packages are prepared and available for pickup.</div>
     `,
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
  }),
  createStep({
    attachTo: { element: '#order-state-info', on: 'bottom' },
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Finish',
        type: 'cancel',
      },
    ],
    canClickTarget: false,
    title: 'Collection Time.',
    text: [
      '<div>Our courier will arrive to collect your packages between the <span class="highlight-shepherd">specified timeframe mentioned</span>. Please ensure someone is available at the pickup location during this period to hand over the packages to our courier.</div>',
    ],
  }),
  createStep({
    id: 'RFC-modal',
    attachTo: { element: '#ready_for_collection', on: 'bottom' },
    title: '📝 Edit Order',
    text: [
      '<p>Made a mistake? No worries, now you can edit orders in Ready for Collection. Go inside the order-&gt; click &apos;Actions&apos; -&gt; &apos;Edit&apos;.</p>',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Finish',
        type: 'cancel',
      },
    ],
    cancelIcon: { enabled: false },
  }),
];

function waitForElementToDisplay(selector: string): Promise<void> {
  return new Promise<void>((resolve) => {
    (function checkIfElementExists(): void {
      if (document.querySelector(selector) !== null) {
        resolve();
      } else {
        setTimeout(checkIfElementExists, 2000);
      }
    })();
  });
}

export default newSteps;
