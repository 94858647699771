import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notification, Button } from 'antd';
import { version as userAppVersion } from '../../package.json';

import { Auth } from '@quiqupltd/quiqupjs';

import Login from '../components/auth/login/v2';
import ResetPassword2 from '../components/auth/reset-password/v2';
import CreateAccount2 from '../components/auth/create-account/v2';
import QuLoading from '@quiqupltd/QuReactSDK/lib/components/qu-loading';
import PrivateRoute from '../components/private-route';
import { ROUTES } from './route';
import { TRACKING_CATEGORIES, trackEvent } from '../globals/analytics';
import PublicRoute from '../components/public-route';
import PasswordRecovery from '../containers/password-recovery/password-recovery';

const SameDayRoutes = lazy(() =>
  import(/*webpackPrefetch: true,  webpackChunkName: "SameDayRoutes" */ './sameday-routes')
);

export const LazySameDayRoutes = (props) => {
  return (
    <Suspense fallback={<QuLoading show />}>
      <SameDayRoutes {...props} />
    </Suspense>
  );
};

const ONE_DAY_IN_MS = 86400000;

const MainRouter = ({ userId, temporaryPassword }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (temporaryPassword) {
      history.push('/password-recovery');
    }
  }, [history, location.pathname, temporaryPassword]);

  useEffect(() => {
    let appVersionIntervalId = null;

    updateAppVersion();
    appVersionIntervalId = setInterval(updateAppVersion, ONE_DAY_IN_MS);

    function updateAppVersion() {
      window
        .fetch('/manifest.json')
        .then((response) => response.json())
        .then((manifestJson) => {
          if (manifestJson.version !== userAppVersion) {
            trackEvent({
              action: 'Version Difference',
              category: TRACKING_CATEGORIES.app,
              user: userId,
              state: {
                userAppVersion,
                latestAppVersion: manifestJson.version,
              },
            });

            const key = `update-app-${Date.now()}`;

            const handleCloseNotification = () => {
              trackEvent({
                action: 'Version Difference Updated',
                category: TRACKING_CATEGORIES.app,
                user: userId,
                state: {
                  userAppVersion,
                  latestAppVersion: manifestJson.version,
                },
              });

              notification.close(key);
              window.location.reload();
            };

            const btn = (
              <Button type="primary" onClick={handleCloseNotification}>
                Yes, update now!
              </Button>
            );

            notification.open({
              message: 'Update your App!',
              description: `You are using an old version of Quiqdash, update now to receive new features and fixes.`,
              duration: 0,
              placement: 'bottomRight',
              btn,
              key,
              onClose: handleCloseNotification,
            });
          }
        });
    }

    return () => {
      if (appVersionIntervalId) {
        clearInterval(appVersionIntervalId);
      }
    };
  }, [userId]);

  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let source = query.get('source');
    if (source) {
      trackEvent({
        action: source,
        category: TRACKING_CATEGORIES.navigation,
        label: 'redirection source',
        user: userId,
        location: location.pathname,
      });
      query.delete('source');
      if (window.location.pathname !== '/login') {
        history.replace({
          pathname: location.pathname,
          search: query.toString(),
        });
      }
    }
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop');
    const token = queryParams.get('token');

    if (shop && token) {
      if (!Auth.hasToken()) {
        history.push(`/login?returnUrl=${encodeURIComponent(`/integrations?shop=${shop}&token=${token}`)}`);
      } else {
        history.push(`/integrations?shop=${shop}&token=${token}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {/* common */}
      <PublicRoute path="/password-recovery" component={PasswordRecovery} exact shouldShowLayout={false} />
      <PublicRoute path="/login" component={Login} exact />
      <PublicRoute path="/reset-password" component={ResetPassword2} exact />
      <PublicRoute path="/create-account" component={CreateAccount2} exact />

      <Route path="/v2/login" render={() => <Redirect to="/login" />} />
      <Route path="/v2/reset-password" render={() => <Redirect to="/reset-password" />} />
      <Route path="/v2/create-account" render={() => <Redirect to="/create-account" />} />

      {/* same-day */}
      <PrivateRoute path={ROUTES.SAMEDAY.BASE} component={LazySameDayRoutes} />

      <Redirect to="/login" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.getIn(['userData', 'id']),
  temporaryPassword: state.user.getIn(['userData', 'temporaryPassword']),
});

MainRouter.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  temporaryPassword: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default connect(mapStateToProps)(MainRouter);
