import { useState, useEffect } from 'react';

function getWindowDimensions(): { width: number; height: number } {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = (): { isPhone: boolean } => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isPhone, setIsPhone] = useState(windowDimensions.width <= 575);
  useEffect(() => {
    function handleResize(): void {
      const newDimensions = getWindowDimensions();
      setWindowDimensions(newDimensions);
      setIsPhone(newDimensions.width <= 575);
    }

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return { isPhone };
};
