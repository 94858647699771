export default function ({ sync = [], async = [] }) {
  const asyncActionTypes = generateAsyncActionTypes(async);
  const syncActionTypes = generateSyncActionTypes(sync);

  return Object.freeze({ ...asyncActionTypes, ...syncActionTypes });
}

function generateSyncActionTypes(actionTypes = []) {
  const actions = {};

  actionTypes.forEach((key) => {
    actions[key] = key;
  });

  return actions;
}

function generateAsyncActionTypes(actionTypes = []) {
  const actions = {};

  actionTypes.forEach((key) => {
    actions[key] = key;
    actions[`${key}_STARTED`] = `${key}_STARTED`;
    actions[`${key}_SUCCESS`] = `${key}_SUCCESS`;
    actions[`${key}_FAILED`] = `${key}_FAILED`;
    actions[`${key}_ERROR`] = `${key}_ERROR`;
  });

  return actions;
}

export function generateAsyncActionTypesToArray(actionPrefix) {
  return [
    actionPrefix,
    `${actionPrefix}_STARTED`,
    `${actionPrefix}_SUCCESS`,
    `${actionPrefix}_FAILED`,
    `${actionPrefix}_ERROR`,
  ];
}
