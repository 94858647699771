import { normaliseResponse } from '@quiqupltd/quiqupjs/lib/network/utils';

export default function quiqupMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (isFunction(action)) {
      return action({ dispatch, getState }); // lets change all action creators to return objects always
    } else if (isPromise(action.promise) || isFunction(action.promise)) {
      next({ type: `${action.type}_STARTED`, payload: action.payload });
      return (isPromise(action.promise) ? action.promise : action.promise())
        .then(
          (payload) =>
            next({
              payload,
              type: `${action.type}_SUCCESS`,
              initialPayload: normaliseResponse(action.payload),
            }),
          (error) =>
            next({
              error,
              type: `${action.type}_FAILED`,
              initialPayload: normaliseResponse(action.payload),
            })
        )
        .catch((error) => {
          /* eslint-disable no-console */
          console.log('quiqup Middleware error, ', error);
          /* eslint-enable no-console */
          next({ error, type: `${action.type}_ERROR` });
        });
    }

    return next(action);
  };
}

function isPromise(action) {
  return action && typeof action.then === 'function';
}

function isFunction(action) {
  return typeof action === 'function';
}
