import React, { ComponentType, useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { Auth } from '@quiqupltd/quiqupjs';
import { LazySameDayRoutes } from '../../routing/routes';

interface PublicRouteProps extends RouteProps {
  component: ComponentType<React.FC>;
  shouldShowLayout: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, shouldShowLayout = true, ...rest }) => {
  const navigate = useHistory();
  useEffect(() => {
    if (Auth.hasToken()) {
      navigate.push('/');
    }
  }, [navigate]);
  return <Route {...rest} component={Auth.hasToken() && shouldShowLayout ? LazySameDayRoutes : Component} />;
};

export default PublicRoute;
