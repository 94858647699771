import { injectGlobal } from 'styled-components';

export const GlobalStyle = injectGlobal`
.shepherd-text {
    font-size: 14px !important;
    font-weight: 300 !important; 
    line-height: 22px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: rgba(127, 116, 144, 1) !important;
  }
  
  .shepherd-arrow {
    left: 5px !important;
  }
  
  .quiqup-tour {
    inset: 10px auto auto -5px !important;
  }

  .shepherd-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(35, 0, 91, 1) !important;
 
  }

  .shepherd-has-title .shepherd-content .shepherd-header {
    background: none !important;
  }

  .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    background: white !important;
  }

  .subtitle-shepherd {
    font-weight: 700 !important;
    color: rgba(108, 101, 247, 1) !important;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .highlight-1-shepherd {
    font-weight: 700 !important;
    color: rgba(127, 116, 144, 1) !important;
  }

  .highlight-shepherd {
    font-weight: 700 !important;
    color: rgba(108, 101, 247, 1) !important;
  }

  .shepherd-button-primary {
    background-color: rgba(108, 101, 247, 1) !important;
  }

  .icon-wrapper-shepherd {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  }
`;
