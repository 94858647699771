import { REGION_CODE_DUBAI } from '../../../utils/constants';
import * as i18n from '../../../utils/i18n';

export const addressAndCCFetching = (state) => {
  return state.user.getIn(['isFetching']);
};

export const isAccountComplete = (state) => {
  if (state.user.getIn(['region', 'isUnitedArabEmirates'])) {
    return true;
  }
};

export const getLocale = (state) => {
  const regionCode = state.user.getIn(['region', 'code'], REGION_CODE_DUBAI);
  const defaultLocale = i18n.getFor(regionCode);
  return state.user.getIn(['locale'], defaultLocale);
};
