import KeyMirror from 'keymirror';

const toastsActionTypes = new KeyMirror({
  TOASTS_ADD: null,
  TOASTS_REMOVE: null,
  TOASTS_REMOVE_ALL: null,
});

Object.freeze(toastsActionTypes);

export default toastsActionTypes;
