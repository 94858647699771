import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px 10px 0px 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${(p): string => p.theme.ghost300};
  overflow: auto;

  .background-icon {
    position: fixed;
    height: 400px;
    left: 0;
    bottom: 0;
  }

  .small-box {
    align-self: center;
    width: 400px;
    margin-bottom: 60px;
  }
`;
