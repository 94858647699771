/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { QuLoading } from '@quiqupltd/QuReactSDK';
import * as Sentry from '@sentry/react';
import { Toaster } from '../../components/toaster';
import * as userActions from '../../redux/modules/user/user.actions';
import * as toastActions from '../../redux/modules/toasts/toasts.actions';
import * as appActions from '../../redux/modules/app/app.actions';
import * as userSelectors from '../../redux/modules/user/user.selectors';

import EventManager from '@quiqupltd/quiqupjs/lib/event-manager/event-manager';

import { page } from '../../globals/analytics';
import { ROUTES } from '../../routing/route';
import GlobalNotifications from '../../components/global-notification';
import UserConfigContextProvider, { UserConfigContext } from '../../contexts/UserConfigContext';
import Nav from '../../components/nav';
import Sidebar from '../../components/sidebar/sidebar';
import { StyledContentContainer, StyledQuiqdash } from './style';
import LayoutSettingsContextProvider, { SidebarStateContext } from '../../contexts/LayoutSettingsContext';
import FreshworksWidget from '../../components/freshworks-widget/freshworks-widget';

export class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    removeToast: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    toasts: PropTypes.array,
    firstName: PropTypes.string,
    businessAccounts: PropTypes.array,
    defaultBusinessAccountId: PropTypes.string,
    location: PropTypes.object,
    isLoginError: PropTypes.bool,
    addToast: PropTypes.func,
    removeAllToasts: PropTypes.func,
    isAccountComplete: PropTypes.bool.isRequired,
    userEmail: PropTypes.string,
    supportNumber: PropTypes.string,
    regionSupportNumber: PropTypes.string.isRequired,
    isAlshayaaAccount: PropTypes.bool.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    userId: PropTypes.number,
    initializeApp: PropTypes.func.isRequired,
    temporaryPassword: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    hasAppFailedInitializing: PropTypes.bool.isRequired,
    isIdle: PropTypes.bool.isRequired,
    isTabVisible: PropTypes.bool.isRequired,
    roles: PropTypes.array,
  };

  state = {
    isDrawerVisible: false,
    showCompletionModal: false,
    isCancellingOrder: false,
    isPhone: false,
  };

  componentDidMount() {
    const { removeAllToasts, userId, userEmail, initializeApp } = this.props;
    const isPhoneSize = () => {
      const { innerWidth: width } = window;
      return width <= 575;
    };
    const handleResize = () => {
      const isPhone = isPhoneSize();
      this.setState({ isPhone });
    };

    const observer = new ResizeObserver(handleResize);
    observer.observe(document.body);

    page('Quiqdash', {
      id: userId || 'Unauthenticated',
      email: userEmail,
    });

    removeAllToasts();
    EventManager.addEventListener('onUnauthenticated', this.handleOnUnauthenticated);
    initializeApp();
  }

  toggleDrawer = () => this.setState((state) => ({ isDrawerVisible: !state.isDrawerVisible }));

  componentDidUpdate(prevProps) {
    const { isLoginError, location, hasAppFailedInitializing } = this.props;

    if (hasAppFailedInitializing) {
      this.logOutUserWithMessage('Error setting up the app, please contact support.');
      return;
    }

    if (isLoginError && prevProps.isLoginError !== isLoginError) {
      this.moveToLoginPage();
      return;
    }

    if (location.pathname !== this.props.location.pathname) {
      this.setState(() => ({ isDrawerVisible: false }));
    }

    if (location.state && location.state.hasAttemptedOrderWithIncompleteAccount) {
      this.setState({ showCompletionModal: true });
    }

    this.handleRedirectByApp();
  }

  componentWillUnmount() {
    EventManager.removeEventListener('onUnauthenticated', this.handleOnUnauthenticated);
  }

  handleRedirectByApp = () => {
    const {
      location: { pathname },
      history,
      temporaryPassword,
    } = this.props;

    // when user has not being fetched yet
    if (temporaryPassword) {
      return;
    }

    let redirectRoute;

    if (!pathname.includes(ROUTES.SAMEDAY.BASE)) {
      redirectRoute = ROUTES.SAMEDAY.ORDERS;
    }

    if (redirectRoute) {
      history.push(redirectRoute);
    }
  };

  moveToLoginPage = () => {
    this.props.history.push('/login');
  };

  handleAddDetails = () => {
    this.moveToAccountPage();
    this.setState({ showCompletionModal: false });
  };

  moveToAccountPage = () => this.props.history.push(ROUTES.SAMEDAY.ACCOUNT);

  moveToSMDBulkPage = () => this.props.history.push(ROUTES.SAMEDAY.BULK);

  moveToSMDSingleShipment = () => this.props.history.push(ROUTES.SAMEDAY.SINGLE_SHIPMENT);

  handleError = (e) => {
    this.logOutUserWithMessage(`Something went wrong. Please contact support.`);
    Sentry.captureMessage('App Container', {
      level: 'error',
      extra: { error: e, props: this.props, state: this.state },
    });
  };

  handleOnUnauthenticated = () =>
    this.logOutUserWithMessage(
      `We’re sorry, your Quiqdash session has expired.
    Please login again to place a new order.`
    );

  logOutUserWithMessage = (message) => {
    this.handleLogout();
    this.props.addToast({ message });
  };

  handleLogout = () => {
    this.props.logOut();
    this.moveToLoginPage();
  };

  handleLogoutClick = () => () => {
    this.handleLogout();
  };

  handleToasterOnClose = (item, index) => {
    this.props.removeToast({ index });
  };

  handleHideModal = () => {
    const { location } = this.props;
    this.setState({ showCompletionModal: false });

    if (location.state && location.state.hasAttemptedOrderWithIncompleteAccount) {
      this.props.history.replace({
        pathname: location.pathname,
        state: {},
      });
    }
  };

  render() {
    const { isDrawerVisible } = this.state;
    const {
      children,
      toasts,
      regionSupportNumber,
      history,
      userId,
      isLoading,
      isIdle,
      isTabVisible,
      firstName,
      businessAccounts,
      defaultBusinessAccountId,
      roles,
    } = this.props;

    return (
      <StyledQuiqdash className="quiq-dash" isPhone={this.state.isPhone}>
        <UserConfigContextProvider isPoolingEnabled={!isIdle && isTabVisible}>
          <LayoutSettingsContextProvider>
            {!isLoading && this.state.isPhone && (
              <Nav
                userId={userId}
                firstName={firstName}
                regionSupportNumber={regionSupportNumber}
                isLoading={isLoading}
                isContactUser={this.isContactUser}
                isDrawerVisible={isDrawerVisible}
                toggleDrawer={this.toggleDrawer}
                handleNewOrderButtonClick={this.handleNewOrderButtonClick}
                handleLogout={this.handleLogoutClick()}
                moveToSMDSingleShipment={this.moveToSMDSingleShipment}
              />
            )}
            <Sidebar
              userId={userId}
              userRoles={roles}
              regionSupportNumber={regionSupportNumber}
              handleLogout={this.handleLogoutClick()}
              firstName={firstName}
              selectedAccount={defaultBusinessAccountId}
              businessAccounts={businessAccounts}
            />
            <SidebarStateContext.Consumer>
              {({ isCollapsed }) => {
                return (
                  <>
                    <StyledContentContainer isPhone={this.state.isPhone} isCollapsed={isCollapsed}>
                      <UserConfigContext.Consumer>
                        {({ notifications }) => {
                          return (
                            notifications.segmentation &&
                            (notifications.segmentation.messageTitle || notifications.segmentation.messageBody) && (
                              <GlobalNotifications
                                title={notifications.segmentation.messageTitle}
                                message={notifications.segmentation.messageBody}
                                isOrderPlacementAllowed={notifications.segmentation.orderPlacementAllowed}
                              />
                            )
                          );
                        }}
                      </UserConfigContext.Consumer>

                      {isLoading ? <QuLoading show /> : children}
                    </StyledContentContainer>
                  </>
                );
              }}
            </SidebarStateContext.Consumer>

            <Toaster toasts={toasts} onClose={this.handleToasterOnClose} history={history} />
            <FreshworksWidget />
          </LayoutSettingsContextProvider>
        </UserConfigContextProvider>
      </StyledQuiqdash>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.getIn(['userData', 'id']),
    roles: state.user.getIn(['userData', 'roles'], []),
    firstName: state.user.getIn(['userData', 'firstname']),
    userEmail: state.user.getIn(['userData', 'email']),
    isContactUser: state.user.getIn(['userData', 'isContactUser']),
    businessAccounts: state.user.get('businessAccounts', []).toJS(),
    defaultBusinessAccountId: state.user.getIn(['defaultBusinessAccount', 'id']),
    regionSupportNumber: state.user.getIn(['region', 'supportPhoneNumber'], ''),
    isLoginError: !!state.user.get('errorMessage'),
    toasts: state.toasts.toArray(),
    isAccountComplete: userSelectors.isAccountComplete(state) || false,
    temporaryPassword: state.user.getIn(['userData', 'temporaryPassword']),
    isLoading: !state.app.isInitialized,
    hasAppFailedInitializing: !state.app.isInitialized && !!state.app.error,
    isIdle: state.app.isIdle,
    isTabVisible: state.app.isTabVisible,
  };
};

const mapDispatchToProps = {
  logOut: userActions.logout,
  removeAllToasts: toastActions.removeAllToasts,
  removeToast(index) {
    return toastActions.removeToast(index);
  },
  addToast(message) {
    return toastActions.addToast(message);
  },
  initializeApp() {
    return appActions.initializeApp();
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
