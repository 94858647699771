import actionTypes from './toasts.action-types';

const { TOASTS_ADD, TOASTS_REMOVE, TOASTS_REMOVE_ALL } = actionTypes;

export function addToast(payload) {
  return { type: TOASTS_ADD, payload };
}

export function removeToast(payload) {
  return { type: TOASTS_REMOVE, payload };
}

export function removeAllToasts() {
  return { type: TOASTS_REMOVE_ALL };
}
