import { combineReducers } from 'redux';

import userReducer from './modules/user/user.reducer';
import toastsReducer from './modules/toasts/toasts.reducer';
import settingsReducer from './modules/settings/settings.reducer';
import preferencesReducer from './modules/preferences/preferences.reducer';
import appReducer from './modules/app/app.reducer';
import userActionTypes from './modules/user/user.action-types.js';

const applicationReducer = combineReducers({
  user: userReducer,
  toasts: toastsReducer,
  settings: settingsReducer,
  preferences: preferencesReducer,
  app: appReducer,
});

const rootReducer = (state, action) => {
  if (action.type === userActionTypes.LOGOUT) {
    state = undefined;
  }

  return applicationReducer(state, action);
};

export default rootReducer;
